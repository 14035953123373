import { useEffect } from "react";
import './App.css';

function App() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://form.jotform.com/jsform/250051824343448";
    script.type = "text/javascript";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Clean up script on unmount
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="App">
      {/* Navigation Bar */}
      <nav className="navbar">
        <div className="navbar-logo">
          <img
            src="https://cdn.shopify.com/s/files/1/0728/2737/1743/files/shopifytaskernewlogodesign-removebg-preview.png?v=1731477862"
            alt="shopifytaskerlogo"
          />
        </div>
        <ul className="navbar-links">
          <li><a href="#home">Home</a></li>
          <li><a href="#services">Services</a></li>
          <li><a href="#about">About</a></li>
          <li><a href="#contact">Contact</a></li>
        </ul>
      </nav>

      <div className="announcement-bar">
              <img
            src="https://cdn.shopify.com/s/files/1/0728/2737/1743/files/coding-1841550_1280.jpg?v=1736232043"
            alt="shopifytaskerlogo"
          />
        <h2>We Accepting projects and Tasks for Q1 2025</h2>
              <div class="HeroTopPitchCtaText">4.9 out of 5 (1,500+ reviews)</div>
      </div>

      <header className="App-header">
        <h1>
          Whether it’s a custom solution or a quick tweak for your Shopify store, we make it easy for you
        </h1>
        <h1>10 years experience in Shopify ecosystem</h1>
        <h2>Accepting clients for Q1 2025</h2>
        {/* Your JotForm will be rendered here */}
      </header>

      <div className="announcement-bar">
        <h2>ShopifyTasker - On Demand Shopify Expert from India!</h2>
      </div>
    </div>
  );
}

export default App;
